/* 문제 레이아웃 페이지 */
.capaTestBox {
  width: 100%;
  min-height: 430px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  margin-bottom: 70px;
}

/* 문제현황판 */
.capaTestTable {
  height: 100%;
  width: 152px;
  padding: 0;
  margin: 0;
  border: 1px solid #a9a9a9;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  z-index: 2;
}

.capaTestTable.over40 {
  width: 304px !important;
  right: -40%;
}

.capaTestTable li {
  list-style: none;
  width: 20%;
  height: 48px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.capaTestTable li:not(:nth-child(5n)) {
  border-right: 1px solid #e7e7e7;
}

.capaTestTable.over40 li:not(:nth-child(10n)) {
  border-right: 1px solid #e7e7e7;
}

.capaTestTable
  li:not(:nth-child(36)):not(:nth-child(37)):not(:nth-child(38)):not(
    :nth-child(39)
  ):not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.capaTestTable li > p {
  margin: 0;
  margin-top: 3px;
  margin-bottom: 4px;
  padding: 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.capaTestTable li > p:hover {
  cursor: pointer;
}

input[id*="q"] {
  margin: 0 auto;
  width: 13px;
}

.submitButton2 {
  width: 100%;
  height: 45px;
  border: 1px solid #fe9e6e;
  color: #fff;
  font-size: 1.35rem;
  background-color: #fe9e6e;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  border: 0;
  color: #fff;
  font-size: 1.35rem;
  width: 100%;
  height: 45px;
}

.submitButton2:hover {
  background-color: #fa7f42;
}

.submitButton2:focus {
  outline: none;
}

/* 문제 페이지 */
.capaTestBox2 {
  width: 100%;
  margin-right: 20px;
  display: inline-flex;
  flex-direction: column;
  max-width: 810px;
  padding: 30px 26px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  box-shadow: 1px 1px 20px 8px #e7e7e7;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .capaTestBox2 {
    max-width: 602px;
  }
}

@media screen and (max-width: 992px) {
  .capaTestBox2 {
    max-width: 500px;
  }
}

.capaTestQuestion1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.capaTestQuestion1 h2 {
  font-weight: 700;
}

.capaTestQuestion1 p {
  font-size: 1.4rem;
  font-weight: 400;
}

.capaTestQuestion2 {
  margin-top: 40px;
  height: auto;
  font-size: 1.5rem;
  line-height: 1.8;
}

.capaTestQuestion2 p {
  font-size: 1.6rem;
  line-height: 1.8;
  color: #575757;
}

.capaQuestionClass {
  position: absolute;
  top: 56px;
  left: 26px;
}

.capaQuestionClass p {
  font-size: 1.3rem;
  color: #a9a9a9;
}

pre.capaQuestionContent {
  background-color: #eeeeee;
  margin-top: 10px;
  padding: 10px;
}

.capaTestImage1 {
  margin-bottom: 8px;
}

.capaTestImage1 img,
.capaTestImage2 img {
  max-width: 100%;
}

.capaTestFooter {
  width: 100%;
  height: 75px;
  margin-top: 4rem;
}

.capaQuestionNums {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.capaQuestionNums p {
  color: #575757;
  font-size: 1.3rem;
}

.buttonsBox {
  width: 100%;
  display: inline-block;
  text-align: right;
}

.prevButton {
  margin-right: 10px;
  background-color: #a9a9a9;
}

.prevButton:hover {
  background-color: #868686;
}

.nextButton {
  background-color: #59b3e7;
}

.nextButton:hover {
  background-color: #2c95d2;
}

.submitButton {
  background-color: #fe9e6e;
}

.submitButton:hover {
  background-color: #fa7f42;
}

.prevButton,
.nextButton,
.submitButton {
  width: 110px;
  height: 45px;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 1.35rem;
}

.prevButton:focus,
.nextButton:focus,
.submitButton:focus {
  outline: none;
}

.prevButton:hover,
.nextButton:hover,
.submitButton:hover {
  cursor: pointer;
}

/* 문제 보기 및 답 작성 컴포넌트 */
.capaTestAnswer {
  margin-top: 20px;
  padding: 0;
  height: auto;
  align-items: center;
}

.choices,
.questions {
  height: auto;
}

div.choices input.form-control {
  display: inline;
  width: auto;
}

.capaTestAnswer input[type="text"].form-control {
  width: 250px;
  height: 35px;
  font-size: 1.4rem;
  border: 1px solid #575757;
}

.capaTestAnswer li:not(:last-child) {
  margin-bottom: 1rem;
}

.capaTestAnswer ul {
  height: inherit;
}

.capaTestAnswer label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.capaTestAnswer label > span,
.capaTestAnswer label > li > span {
  vertical-align: baseline;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8;
}

.out-choices {
  display: flex;
  justify-content: center;
}

.choices-number {
  font-weight: bold;
}

.in-choices {
  white-space: pre-wrap;
}
